import React from "react"
import Layout from "../components/layout"
import Meta from "../components/meta"
import Image from "../components/image"
import Hero from "../components/hero"

export default () => (
  <Layout>
    <Meta title="会社概要" />
    <Hero title="会社概要" />
    <div className="max-w-3xl mx-auto px-2">
      <Image
        filename="office.png"
        className="mb-3 w-64 mx-auto"
        alt="会社の外観"
      />
      <div className="text-gray-700 mb-20">
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">会社名</dt>
          <dd className="w-full">株式会社メルクライム</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">住所</dt>
          <dd className="w-full">
            〒223-0056
            <br />
            神奈川県横浜市港北区新吉田町4543
          </dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">電話番号</dt>
          <dd className="w-full">045-594-3606</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">FAX</dt>
          <dd className="w-full">045-548-6229</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">営業時間</dt>
          <dd className="w-full">10：00－19：00</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">定休日</dt>
          <dd className="w-full">日曜日・祝日</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">設立</dt>
          <dd className="w-full">2007年2月2日</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">代表取締役</dt>
          <dd className="w-full">生天目 正</dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">代表経歴</dt>
          <dd className="w-full">
            昭和54年　株式会社ヤナセ（ウェスタン自動車）入社。ユニット課にて約28年間リビルド事業に従事。
            <br />
            平成18年　株式会社ヤナセ退社。
            <br />
            平成19年　株式会社メルクライム設立。現在に至る。
          </dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">事業内容</dt>
          <dd className="w-full">
            ・自動車及び自動車部品の輸出入・販売・修理
            <br />
            ・メルセデスベンツ用AT及びトルクコンバータ・オーバーホール
            <br />
            ・メルセデスベンツ用エンジン・オーバーホール
            <br />
            ・パワーステアリングギアボックス及びポンプ・オーバーホール
            <br />
            ・リビルド電子部品の輸出入・販売
          </dd>
        </dl>
        <dl className="flex px-2 py-3 border-b border-gray-300">
          <dt className="w-32 font-bold">企業理念</dt>
          <dd className="w-full">技術と信頼と・良いものをいつまでも人と共に</dd>
        </dl>
      </div>
    </div>
  </Layout>
)
