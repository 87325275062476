import React from "react"

export default props => (
  <div
    className="w-full flex items-center h-40 bg-cover justify-center mb-6"
    style={{
      backgroundImage:
        'url("https://images.unsplash.com/photo-1549275301-c9d60945be6b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80")',
    }}
  >
    <span className="text-white font-bold mb-0 text-2xl">{props.title}</span>
  </div>
)
